



























































import { Component, Vue, Watch } from "vue-property-decorator";

import BaseTooltip from "@/components/menu/BaseTooltip.vue";
import SwitchSlider from "@/components/menu/SwitchSlider.vue";
import { State } from "@/store/settings";

@Component({
  components: {
    BaseTooltip,
    SwitchSlider,
  },
})
export default class SettingsImportant extends Vue {
  settings: State["important"] = { ...this.$store.state.settings.important };

  @Watch("settings", { deep: true })
  onSettingsChanged(): void {
    this.$store.commit("settings/setImportantSettings", this.settings);
  }
}
